import React, { useState } from "react";
import { Link } from "react-router-dom";

function Main() {
  const [ytshow, setytShow] = useState(false);

  return (
    <>
      <footer>
        <div className="container">
          <div className="foote_widget contact_info">
            <div className="row py_50">
              <div className="col-md-7 direction_block">
                <p style={{ whiteSpace: 'nowrap' }}>
                  Welcome to La Hacienda <br />
                  Mexican Restaurant <br />
                  Contact Salt Lake City: <Link to="tel:+18019730738">+1 (801) 973-0738</Link>
                  <br />
                  Contact Draper: <Link to="tel:+18015712737">+1 (801) 571-2737</Link>
                  <br />
                  Contact South Jordan: <Link to="tel:+18015597374">+1 (801) 559-7374</Link>
                  <br />
                  Contact Hacienda Mexican Grill: <Link to="tel:+18019530667">+1 (801) 953-0667</Link>
                </p>

                {/* <Link to="/contact" className="btn_text">
                  get direction <i className="icofont-long-arrow-right" />
                </Link> */}
              </div>
              <div className="col-md-5 text-md-right timing_block">
                <p>
                  Sunday - Thursday : 10AM - 9PM <br />
                  Friday - Saturday : 10AM - 10PM
                </p>
                <Link
                  to="/locations"
                  className="btn btn_primary mt-3"
                  id="bookingForm_modelTitle"
                  data-toggle="modal"
                  data-target="#bookingForm_model"
                // onClick={() => setytShow(true)}
                >
                  Order Online
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="foote_widget newsletter_block">
            <div className="row py_50">
              <div className="col-md-7 offer_block">
                <h2>Get News &amp; Offers</h2>
                <p>
                  Subscribe us &amp; Get <span>25% Off.</span>
                </p>
                <form>
                  <div className="form-group">
                    <span className="icon position-absolute">
                      <i className="icofont-envelope-open" />
                    </span>
                    <input
                      type="text"
                      placeholder="Your email"
                      className="form-control"
                      required=""
                    />
                    <button className="btn btn_primary">subscribe now</button>
                  </div>
                </form>
              </div>
              <div className="col-md-5">
                <ul className="social_media">
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/" target="_blank">
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/" target="_blank">
                      Youtube
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          <div className="foote_widget menu_links">
            <div className="row">
              <div className="col-md-12">
                <ul className="links d-flex justify-content-center">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/menu">Menu</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/locations">Locations</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <a href="https://heartlandgiftcard.com/CheckBalance.aspx" target="_blank">Check Gift Card Balance</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 copy_right text-center">
              <p>
                © 2023 La Hacienda Mexican Restaurant. All Rights Reserved | Crafted by{" "}
                <Link to="https://keltonhale.com" target="blog-list">
                  Hale Designs
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="go_top" id="Gotop">
          <span>
            <i className="icofont-rounded-up" />
          </span>
        </div>
      </footer>
      {ytshow && (
        <div
          className="modal fade booking_model show"
          id="bookingForm_model"
          tabIndex={-1}
          aria-labelledby="bookingForm_modelTitle"
          style={{ display: "block", paddingRight: 17 }}
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header text-center border-0 d-block p-0">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setytShow(false)}
                >
                  <span aria-hidden="true">
                    <i className="icofont-close-line" />
                  </span>
                </button>
                <h2 className="mb-2">Online Reservation</h2>
                <p>
                  Booking request <a href="tel:+88123123456">+88-123-123456</a>{" "}
                  or fill out the order form
                </p>
              </div>
              <div className="modal-body">
                <div className="booking_form">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Your Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Phone Number"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <span className="icon">
                            <i className="icofont-user-alt-7" />
                          </span>
                          <input
                            type="text"
                            className="form-control has_icon"
                            placeholder="1 Person"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <span className="icon">
                            <i className="icofont-calendar" />
                          </span>
                          <input
                            type="date"
                            className="form-control  flex-row-reverse"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <span className="icon">
                            <i className="icofont-clock-time" />
                          </span>
                          <input
                            type="time"
                            className="form-control has_icon  flex-row-reverse pl-0"
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            placeholder="Message"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button className="btn btn_primary w-100 ">
                          book a table
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Main;
