import React from "react";

import Icon01 from "../../assets/images/title_icon.png";
import abt01 from "../../assets/images/about/inside.jpg";
import abt02 from "../../assets/images/about/draper.jpg";
import abt03 from "../../assets/images/about/slc-lahacienda.jpg";
import abt04 from "../../assets/images/about/indoor.jpg";
import abt05 from "../../assets/images/about/outdoor.jpg";

function AbtBanner() {
  return (
    <>
      <section className="abt_banner">

        <div className="section_title text-center">
          <span className="icon" data-aos="fade-down" data-aos-duration={1500}>
            <img src={Icon01} alt="img" />
          </span>
          <span
            className="sub_text mb-1"
            data-aos="fade-down"
            data-aos-duration={1500}
          >
            Our Story{" "}
          </span>
          <h2 data-aos="zoom-in" data-aos-duration={1500}>
            Savor the Essence of Mexico in Every Bite at La Hacienda Mexican Restaurant – Utah’s
            Culinary Gem.
          </h2>
          <p className="lead" data-aos="fade-up" data-aos-duration={1500}>
            La Hacienda Mexican Restaurant blends a love for Mexico's vibrant flavors with a flair
            for exquisite dining in Utah. Our founders, from Mexico,
            were inspired by its rich culinary heritage. They meticulously
            crafted a menu that brings traditional Mexican recipes to life with
            a local twist. Each visit to La Hacienda Mexican Restaurant promises not just a meal,
            but a journey through the colorful and diverse tastes of Mexico.
          </p>
        </div>
        <div className="abt_img">
          <div
            className="img"
            data-aos="fade-right"
            data-aos-duration={1500}
            data-aos-delay={200}
          >
            <img src={abt01} alt="img" />
          </div>
          <div
            className="img"
            data-aos="fade-right"
            data-aos-duration={1500}
            data-aos-delay={100}
          >
            <img src={abt02} alt="img" />
          </div>
          <div className="img" data-aos="fade-up" data-aos-duration={1500}>
            <img src={abt03} alt="img" />
          </div>
          <div
            className="img"
            data-aos="fade-left"
            data-aos-duration={1500}
            data-aos-delay={100}
          >
            <img src={abt04} alt="img" />
          </div>
          <div
            className="img"
            data-aos="fade-left"
            data-aos-duration={1500}
            data-aos-delay={200}
          >
            <img src={abt05} alt="img" />
          </div>
        </div>
      </section>
    </>
  );
}

export default AbtBanner;
