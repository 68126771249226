import * as prismic from "@prismicio/client";

// Fill in your repository name
export const repositoryName = "la-hacienda";

export const client = prismic.createClient(repositoryName, {
  // If your repository is private, add an access token
  // accessToken: process.env.REACT_APP_PRISMIC_TOKEN,
  accessToken: 'MC5aWUROZWhFQUFDY0E0bEIy.ewPvv73vv71b77-9Ne-_vTJ877-9b--_ve-_ve-_ve-_vV4_d19mdO-_vXBZ77-9Yg_vv73vv73vv73vv70',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the custom types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.

  routes: [
    // {
    //   type: "homepage",
    //   path: "/",
    // },
    {
      type: "menu",
      path: "/menu",
    },
  ],
});
