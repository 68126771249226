import React, { useEffect } from "react";

const DynamicMenu = ({ menuItems = [] }) => {
  useEffect(() => {
    console.log(typeof menuItems, Array.isArray(menuItems));
  }, []);

  if (menuItems.length < 1) return <div>hello</div>;

  // Grouping the menu items by category
  const menuGroups = menuItems.reduce((acc, item) => {
    // console.log("Current item:", item);
    const category = item.category;

    if (!acc[category]) {
      acc[category] = [];
    }

    acc[category].push(item);

    return acc;
  }, {});

  console.log(menuGroups);

  // Rendering the groups
  const categories = Object.keys(menuGroups);
  const leftSideCategories = categories.filter((_, index) => index % 2 === 0);
  const rightSideCategories = categories.filter((_, index) => index % 2 !== 0);

  const renderMenuList = (category) => {
    console.log('category', category)
    return (
      <div className="menulist_box">
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap: 10 }}>
          <h3 className="menusub_text">{category}</h3>
          {category === 'combinations' && <span style={{ textAlign: 'right', lineHeight: 1.2 }}>Served with rice & beans. Whole beans upon request</span>
          }
          {
            category === 'tortas' && <span style={{ textAlign: 'right', lineHeight: 1.2 }}>Served with lettuce, tomato, mayo, mustard, avocado, onions, and french fries.</span>
          }
          {
            category === 'mariscos' && <span style={{ textAlign: 'right', lineHeight: 1.2 }}>Served with rice & beans. Whole beans upon request.</span>
          }
          {
            category === 'meat-plates' && <span style={{ textAlign: 'right', lineHeight: 1.2 }}>Served with rice, beans, & tortillas. Whole beans upon request.</span>
          }
          {
            category === 'fajitas' && <span style={{ textAlign: 'right', lineHeight: 1.2 }}>Grilled with fresh bell peppers, onions, tomatoes, cilantro, and mushrooms. Served with rice, beans, guacamole, sour cream & tortillas.</span>
          }
          {
            category === 'chile-platters' && <span style={{ textAlign: 'right', lineHeight: 1.2 }}>Served with rice, beans, & tortillas.</span>
          }
          {
            category === 'chimichangas' && <span style={{ textAlign: 'right', lineHeight: 1.2 }}>A deep fried burrito filled with meat and cheese. Served with rice, beans, guacamole & sour cream.</span>
          }
          {
            category === 'quesadilla-de-masa' && <span style={{ textAlign: 'right', lineHeight: 1.2 }}>Served with rice, beans, guacamole, & sour cream.</span>
          }
          {
            category === 'tacos' && <span style={{ textAlign: 'right', lineHeight: 1.2 }}>Served with rice & beans.</span>
          }
          {
            category === 'tamales' && <span style={{ textAlign: 'right', lineHeight: 1.2 }}>Served with rice & beans.</span>
          }
          {
            category === 'enchiladas' && <span style={{ textAlign: 'right', lineHeight: 1.2 }}>Served with rice & beans.</span>
          }
          {
            category === 'burritos' && <span style={{ textAlign: 'right', lineHeight: 1.2 }}>Served with rice & beans.</span>
          }

          {
            category === 'fluatas' && <span style={{ textAlign: 'right', lineHeight: 1.2 }}>Served with rice, beans, guacamole, & sour cream.</span>
          }
        </div>
        <ul style={{ marginTop: 10 }}>
          {menuGroups[category].map((item, index) => (
            <li key={index}>
              <div className="mainCource">
                <div className="dishName">
                  <h4>{item.item_name}</h4>
                  {/* Add 'NEW' span if needed */}
                </div>
                <div className="doted" />
                {item.price && <span className="price">${item.price}</span>}
              </div>
              <div className="description">
                <p>{item.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div
      className="tab-pane fade show active"
      id="profile"
      role="tabpanel"
      aria-labelledby="breakfast-tab"
    >
      <div className="menulist_wraper">
        <div className="menulist left_side">
          {leftSideCategories.map((category) => renderMenuList(category))}
        </div>
        <div className="menulist right_side">
          {rightSideCategories.map((category) => renderMenuList(category))}
        </div>
      </div>
      {/* Add download button if needed */}
    </div>
  );
};

export default DynamicMenu;
