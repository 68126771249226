import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Team01 from "../../assets/images/knife_fire.png";
import Team02 from "../../assets/images/chef_01.png";
import Team03 from "../../assets/images/chef_02.png";
import Team04 from "../../assets/images/chef_03.png";
import TopImg from "../../assets/images/home/location_top_img.png";
import slc from "../../assets/images/locations/slc-lahacienda.jpg";
import sj from "../../assets/images/locations/front1.jpg";
import draper from "../../assets/images/locations/draper-lahacienda.jpg";
import haciendaGrill from "../../assets/images/haciendaGrill.png";

const Team = {
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};

function OurLocations(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleOrderClick = (location) => {
    setSelectedLocation(location);
    setModalVisible(true);
  };

  const handleLocationSelect = (url) => {
    window.location.href = url;
  };

  return (
    <>
      <section
        ref={props.passedRef}
        className="row_inner_am our_team_section light_texchur"
      >
        <div className="side_shape">
          <img src={TopImg} alt="img" />
        </div>
        <div className="container">
          <div
            className="section_title text-center"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <span className="sub_text">Our Locations</span>
            <h2>VIEW OUR SPOTS</h2>
          </div>
          <div
            className="team_slider_wraper"
            data-aos="fade-in"
            data-aos-duration={1500}
          >
            <Slider
              className=""
              id="team_slider"
              {...Team}
              slidesToShow={3}
              arrows={false}
            >
              <div className="item">
                <div className="team_card">
                  <div className="img">
                    <img src={slc} alt="img" />
                  </div>
                  <div className="info">
                    <h3>Salt Lake City</h3>
                    <p>1248 S Redwood Rd, Salt Lake City, UT 84104</p>

                    {!props.isHome && (
                      <button
                        onClick={() =>
                          handleOrderClick(
                            "https://lahaciendaslc.hrpos.heartland.us/menu"
                          )
                        }
                        className="btn btn_primary mt-1 mb-1"
                      >
                        Order Online
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="team_card">
                  <div className="img">
                    <img src={draper} alt="img" />
                  </div>
                  <div className="info">
                    <h3>Draper</h3>
                    <p>12434 Minuteman Dr, Draper, UT 84020</p>

                    {!props.isHome && (
                      <button
                        onClick={() =>
                          handleOrderClick(
                            "https://lahaciendadraper.hrpos.heartland.us/"
                          )
                        }
                        className="btn btn_primary mt-1 mb-1"
                      >
                        Order Online
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="team_card">
                  <div className="img">
                    <img src={sj} alt="img" />
                  </div>
                  <div className="info">
                    <h3>South Jordan</h3>
                    <p>11718 3600 W, South Jordan, UT 84095</p>

                    {!props.isHome && (
                      <button
                        onClick={() =>
                          handleOrderClick(
                            "https://lahaciendasouthjordan.hrpos.heartland.us/menu"
                          )
                        }
                        className="btn btn_primary mt-1 mb-1"
                      >
                        Order Online
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </Slider>
            {props.isHome && (
              <div className="text-md-center mt-2">
                <Link
                  to="/locations"
                  className="btn btn_primary mt-3"
                  id="bookingForm_modelTitle"
                  data-toggle="modal"
                  data-target="#bookingForm_model"
                  onClick={() => {
                    console.log("clicked");
                  }}
                >
                  Order Online
                </Link>
              </div>
            )}
          </div>
          <div className="container">
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 50,
                }}
              >
                <h3>Visit our sister company, Hacienda Mexican Grill</h3>
                <img
                  style={{ height: 150, width: "auto", borderRadius: 20 }}
                  src={haciendaGrill}
                  alt="img"
                />

                <div
                  className="info"
                  style={{
                    marginTop: 20,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p style={{ textAlign: "center" }}>
                    797 N Redwood Rd, Salt Lake City, UT 84116
                  </p>
                  {props.isHome && <span className="position"></span>}
                  {!props.isHome && (
                    // order here button
                    <a
                      href="https://haciendagrillrosepark.hrpos.heartland.us/menu"
                      target="_blank"
                      className="btn btn_primary mt-1 mb-1"
                    >
                      Order Online
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {modalVisible && (
        <div className="modal" style={{
          display: "block",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9999,
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "auto",

        }}>
          <div className="modal-content" style={{
            backgroundColor: "#fefefe",
            margin: "15% auto",
            padding: 20,
            border: "1px solid #888",
            width: "50%",
            borderRadius: 20,
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
          }}>
            <h4 style={{
              textAlign: "center",
              marginBottom: 20,
              color: "black",
            }}>Select Order Location</h4>
            <button
              onClick={() =>
                handleLocationSelect(
                  "https://lahaciendaslc.hrpos.heartland.us/menu"
                )
              }
              style={{
                backgroundColor: "#f8d7da",
                color: "black",
                padding: 10,
                borderRadius: 10,
                marginBottom: 10,
                width: "100%",
                border: "none",
                cursor: "pointer",
              }}
            >
              La Hacienda - Salt Lake City
            </button>
            <button
              style={{
                backgroundColor: "#f8d7da",
                color: "black",
                padding: 10,
                borderRadius: 10,
                marginBottom: 10,
                width: "100%",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() =>
                handleLocationSelect(
                  "https://lahaciendadraper.hrpos.heartland.us/"
                )
              }
            >
              La Hacienda - Draper
            </button>
            <button
              style={{
                backgroundColor: "#f8d7da",
                color: "black",
                padding: 10,
                borderRadius: 10,
                marginBottom: 10,
                width: "100%",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() =>
                handleLocationSelect(
                  "https://lahaciendasouthjordan.hrpos.heartland.us/menu"
                )
              }
            >
              La Hacienda - South Jordan
            </button>
            <button
              style={{
                backgroundColor: "#f8d7da",
                color: "black",
                padding: 10,
                borderRadius: 10,
                marginBottom: 10,
                width: "100%",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() =>
                handleLocationSelect(
                  "https://haciendagrillrosepark.hrpos.heartland.us/menu"
                )
              }
            >
              Hacienda Mexican Grill - Rose park
            </button>
            <button onClick={() => setModalVisible(false)}>Close</button>
          </div>
        </div>
      )}
    </>
  );
}

export default OurLocations;
