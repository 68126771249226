import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Ctn01 from "../../assets/images/vegis.png";
import Ctn02 from "../../assets/images/left-veg.png";
import Ctn03 from "../../assets/images/contact_icon.png";
import Ctn04 from "../../assets/images/locations/slc-lahacienda.jpg";
import emailjs from '@emailjs/browser'

function ContactUS() {
  const form = useRef();
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      emailjs.sendForm('service_phg10nl', "template_3cbeqls", form.current, {
        publicKey: 'IzQEcWKTf0BOxEpv_'
      }).then(() => {
        console.log('SUCCESS');
        setSuccess(true);
      },
        (error) => {
          console.log('FAILED', error);
        })
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);

  }
  return (
    <>

      {/* modal that pops up for success for 10 seconds */}
      {success && <div className="modal" style={{ display: 'block' }}>
        <div className="modal-content bg-dark">
          <span className="close" onClick={() => setSuccess(false)}>&times;</span>
          <h2 className="">Success!</h2>
          <p>Your message has been sent successfully!</p>
        </div>
      </div>}

      <section className="contact_us_section">
        <div className="right_shape position-absolute">
          {/* <img src={Ctn01} alt="img" /> */}
        </div>
        <div className="left_shape position-absolute">
          {/* <img src={Ctn02} alt="img" /> */}
        </div>
        <div className="container">
          <div className="section_title text-center">
            <span
              className="icon"
              data-aos="fade-down"
              data-aos-duration={1500}
            >
              <img src={Ctn03} alt="img" />
            </span>
            <span
              className="sub_text mb-1"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              Contact us
            </span>
            <h2 data-aos="fade-up" data-aos-duration={1500}>
              Get in touch with us
            </h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                <img className="radius_img" src={Ctn04} alt="img" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact_box">
                <ul
                  className="contact_listing"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <li>
                    <p className="sub_title">Call us</p>
                    <div className="cnt_block">
                      <Link to="tel:+18018730738">SALT LAKE CITY - (801) 873-0738</Link>
                      <span>
                        <i className="icofont-ui-call" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <Link to="tel:+18015712737">DRAPER - (801) 571-2737</Link>
                      <span>
                        <i className="icofont-ui-call" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <Link to="tel:+18015597374">SOUTH JORDAN - (801) 559-7374</Link>
                      <span>
                        <i className="icofont-ui-call" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <Link to="tel:+18019530667">Hacienda Grill - ROSE PARK - (801) 953-0667</Link>
                      <span>
                        <i className="icofont-ui-call" />
                      </span>
                    </div>
                  </li>
                  <li>
                    <p className="sub_title">Email us</p>
                    <div className="cnt_block">
                      <Link to="#">
                        la_hacienda@comcast.net
                      </Link>
                      <span>
                        <i className="icofont-envelope-open" />
                      </span>
                    </div>
                  </li>
                  <li>
                    <p className="sub_title">Find us</p>
                    <div className="cnt_block">
                      <a href="https://www.google.com/maps/place/La+Hacienda+Salt+Lake/@40.7423285,-111.9424174,17z/data=!3m1!4b1!4m6!3m5!1s0x8752f4bcde605a91:0x7df64193be3901f6!8m2!3d40.7423285!4d-111.9398425!16s%2Fg%2F1wh4gb9k?entry=ttu" target="_blank">La Hacienda, Salt Lake City UT 84104</a>
                      <span>
                        <i className="icofont-location-pin" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <a href="https://www.google.com/maps/place/La+Hacienda+Draper/@40.5252998,-111.8916414,17z/data=!3m1!4b1!4m6!3m5!1s0x87528716d0a0bc39:0x1bfbd46641479e5e!8m2!3d40.5252998!4d-111.8890665!16s%2Fg%2F1tk8p1vy?entry=ttu" target="_blank">La Hacienda, Draper UT 84020</a>
                      <span>
                        <i className="icofont-location-pin" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <a href="https://www.google.com/maps/place/La+Hacienda+South+Jordan/@40.5384869,-111.9795646,17z/data=!3m1!4b1!4m6!3m5!1s0x87528566cdda3cb7:0xe763d19b2cdeaa19!8m2!3d40.5384869!4d-111.9769897!16s%2Fg%2F11k2jr2rdz?entry=ttu" target="_blank">La Hacienda, South Jordan UT 84095</a>
                      <span>
                        <i className="icofont-location-pin" />
                      </span>
                    </div>
                    <div className="cnt_block">
                      <a href="https://www.google.com/maps/place/Hacienda+Mexican+Grill/@40.7863068,-111.9433721,17z/data=!3m1!4b1!4m6!3m5!1s0x8752f40dfb95461b:0xef3e4f5a637d8de!8m2!3d40.7863028!4d-111.9407972!16s%2Fg%2F11c0rh247k?entry=ttu" target="_blank">Hacienda Mexican Grill, Rose Park UT 84116</a>
                      <span>
                        <i className="icofont-location-pin" />
                      </span>
                    </div>
                  </li>
                </ul>
                <form ref={form} data-aos="fade-up" data-aos-duration={1500}
                  onSubmit={sendEmail}
                >
                  <h3>Say hi, Don’t be shy!</h3>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                      name="name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      name="email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      name="phone_number"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Message"
                      defaultValue={""}
                      name="message"
                    />
                  </div>
                  <div className="form-group text-right">
                    <button type="submit" className="btn btn_primary"
                    >
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div>
            {/* Leave a review section */}
            <div className="section_title text-center" >

              <span
                className="sub_text mb-1"
                data-aos="fade-up"
                data-aos-duration={1500}
              >
                Review Us
              </span>
              <h2 data-aos="fade-up" data-aos-duration={1500}>
                Tell us how you really feel
              </h2>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: 30, flexWrap: 'wrap', gap: 10 }}>
                {/* Display an image of a google logo to select to review */}
                <div >
                  <a href="https://www.google.com/maps/place/La+Hacienda+Salt+Lake/@40.7423285,-111.9424174,17z/data=!4m8!3m7!1s0x8752f4bcde605a91:0x7df64193be3901f6!8m2!3d40.7423285!4d-111.9398425!9m1!1b1!16s%2Fg%2F1wh4gb9k?entry=ttu" target="_blank">
                    <h6>Salt Lake City</h6>
                    <img src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_92x30dp.png" alt="Google Logo" />
                  </a>
                </div>
                {/* DO ONE FOR SALT LAKE CITY GOOGLE, DRAPER, AND SOUTH JORDAN LOCATION */}
                <div>
                  <a href="https://www.google.com/maps/place/La+Hacienda+Draper/@40.5252984,-111.8917068,17z/data=!4m8!3m7!1s0x87528716d0a0bc39:0x1bfbd46641479e5e!8m2!3d40.5252998!4d-111.8890665!9m1!1b1!16s%2Fg%2F1tk8p1vy?entry=ttu" target="_blank">
                    <h6>Draper</h6>
                    <img src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_92x30dp.png" alt="Google Logo" />
                  </a>
                </div>
                <div>
                  <a href="https://www.google.com/maps?sca_esv=fe09e3d337d2e27f&rlz=1C5CHFA_enUS777US777&output=search&q=la+hacienda+south+jordan&source=lnms&entry=mc" target="_blank">
                    <h6>South Jordan</h6>
                    <img src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_92x30dp.png" alt="Google Logo" />
                  </a>
                </div>
                <div>
                  <a href="https://www.google.com/maps/place/Hacienda+Mexican+Grill/@40.7643083,-111.9815195,13z/data=!4m12!1m2!2m1!1sHacienda+Mexican+Grill+Salt+Lake+City!3m8!1s0x8752f40dfb95461b:0xef3e4f5a637d8de!8m2!3d40.7863028!4d-111.9407972!9m1!1b1!15sCiVIYWNpZW5kYSBNZXhpY2FuIEdyaWxsIFNhbHQgTGFrZSBDaXR5WiciJWhhY2llbmRhIG1leGljYW4gZ3JpbGwgc2FsdCBsYWtlIGNpdHmSARJtZXhpY2FuX3Jlc3RhdXJhbnTgAQA!16s%2Fg%2F11c0rh247k?entry=ttu" target="_blank">
                    <h6>Hacienda Grill - Rose Park</h6>
                    <img src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_92x30dp.png" alt="Google Logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="map" data-aos="fade-up" data-aos-duration={1500}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d122285.89276945118!2d-111.90448778491486!3d40.760779379326824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x874d80dc001c0c6b%3A0x3b4a0dbdd6eae6b3!2sSalt%20Lake%20City%2C%20UT%2C%20USA!5e0!3m2!1sen!2sin!4v1685309226223!5m2!1sen!2sin"
              height={450}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactUS;
