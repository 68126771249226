import React from "react";
import Dish01 from "../../assets/images/about/food.jpg";
import Icon01 from "../../assets/images/outj_icon1.png";
import Icon02 from "../../assets/images/outj_icon2.png";
import Icon03 from "../../assets/images/outj_icon3.png";

function Journey() {
  return (
    <>
      <section className="row_am our_journey bg_white_textcher">
        <div
          className="side_shape position-absolute"
          data-aos="fade-in"
          data-aos-duration={1500}
        >
          <img src={Dish01} alt="img" />
        </div>
        <div className="container">
          <div className="row_am">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <span className="sub_text">What Sets Us Apart</span>
              <h2>
                La Hacienda's Story: Flavorful Beginnings <br /> in Authentic
                Mexican Cuisine
              </h2>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div
                  className="joueney_box"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="icon">
                    <img src={Icon01} alt="img" />
                  </div>
                  <h3>Vibrant Atmosphere</h3>
                  <p>
                    Nestled in the heart of Utah, La Hacienda Mexican Restaurant offers a lively
                    and inviting atmosphere where every meal is a celebration of
                    Mexican culture and gastronomy, perfect for family
                    gatherings, business, romantic events, and any other occasion.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="joueney_box"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="icon">
                    <img src={Icon02} alt="img" />
                  </div>
                  <h3>Online Ordering</h3>
                  <p>
                    Enjoy the flavors of La Hacienda Mexican Restaurant at your home with our
                    efficient online ordering options. Savor our authentic Mexican
                    dishes in the comfort of your home, bringing a piece of our
                    kitchen to yours. Just select order online and pick up in store.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="joueney_box"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="icon">
                    <img src={Icon03} alt="img" />
                  </div>
                  <h3>Exceptional Service</h3>
                  <p>
                    At La Hacienda Mexican Restaurant, we pride ourselves on our exceptional
                    service. Our dedicated team strives to create an
                    unforgettable dining experience, ensuring that each visit is
                    as memorable as the last.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Journey;
