import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  useSinglePrismicDocument,
  useFirstPrismicDocument,
} from "@prismicio/react";
import DynamicMenu from "./DynamicMenu";

function OfferMenu2() {
  const [selectedTab, setSelectedTab] = useState("lunch");
  const [selectedinner, setselectedinner] = useState("dinner");
  const [selectebreakfast, setselectebreakfast] = useState("breakfast");
  const [selectewinelist, setselectewinelist] = useState("winelist");
  const [selectedDessert, setSelectedDessert] = useState("dessert");
  const [breakfast, setBreakfast] = useState([]);
  const [lunch, setLunch] = useState([]);
  const [drinks, setDrinks] = useState([]);
  const [desserts, setDesserts] = useState([]);
  const [document] = useSinglePrismicDocument("menu");

  useEffect(() => {
    console.log("doc", document);
    if (document) {
      filterMenu();
    }
  }, [document]);

  const filterMenu = () => {
    const breakfast = document.data.body.filter(
      (item) => item.slice_type === "breakfast"
    );
    const lunch = document.data.body.filter(
      (item) => item.slice_type === "lunch_dinner"
    );
    const drinks = document.data.body.filter(
      (item) => item.slice_type === "drinks"
    );
    const desserts = document.data.body.filter(
      (item) => item.slice_type === "desserts"
    );

    console.log("breakfast", breakfast[0].items);
    console.log("lunch", lunch[0].items);
    console.log("drinks", drinks[0].items);
    console.log("desserts", desserts[0].items);
    setLunch(lunch[0].items);
    setBreakfast(breakfast[0].items);
    setDrinks(drinks[0].items);
    setDesserts(desserts[0].items);

    console.log('breakfast', breakfast[0].items);
    console.log('lunch', lunch[0].items);
    console.log('drinks', drinks[0].items);
    console.log('desserts', desserts[0].items);

    // // ... existing logic for breakfast, lunch, and drinks
    // const organizedBreakfast = organizeMenuItemsByCategory(breakfast[0]?.items);
    // setBreakfast(organizedBreakfast);
    // Repeat for lunch and drinks if necessary
  };

  const handleTabClick = (tabId) => {
    setSelectedTab(tabId);
    setselectedinner(tabId);
    setselectebreakfast(tabId);
    setselectewinelist(tabId);
    setSelectedDessert(tabId);
  };

  // Utility function to organize menu items by category
  // const organizeMenuItemsByCategory = (items) => {
  //   const categories = {};
  //   items.forEach((item) => {
  //     const categoryName = item.category;
  //     if (!categories[categoryName]) {
  //       categories[categoryName] = [];
  //     }
  //     categories[categoryName].push(item);
  //   });
  //   return categories;
  // };

  return (
    <>
      <section className="row_am offer_menu_section">
        <div className="container">
          <div
            className="section_title text-center"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <span className="sub_text">Our menu</span>
            <h2>Authentic Mexican Delights in the Heart of Utah.</h2>
          </div>
          <ul
            className="nav nav-tabs"
            id="myTab"
            role="tablist"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <li className="nav-item">
              <Link
                className={`nav-link ${selectedTab === "breakfast" ? "active" : ""
                  }`}
                onClick={() => handleTabClick("breakfast")}
                id="dinner-tab"
                data-toggle="tab"
                to="#dinner"
                role="tab"
                aria-controls="dinner"
                aria-selected="true"
              >
                Breakfast
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${selectedTab === "lunch" ? "active" : ""
                  }`}
                onClick={() => handleTabClick("lunch")}
                id="lunch-tab"
                data-toggle="tab"
                to="#lunch"
                role="tab"
                aria-controls="lunch"
                aria-selected="true"
              >
                Lunch/Dinner
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${selectedTab === "drinks" ? "active" : ""
                  }`}
                onClick={() => handleTabClick("drinks")}
                id="breakfast-tab"
                data-toggle="tab"
                to="#breakfast"
                role="tab"
                aria-controls="breakfast"
                aria-selected="true"
              >
                Drinks
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${selectedTab === "dessert" ? "active" : ""
                  }`}
                onClick={() => handleTabClick("dessert")}
                id="breakfast-tab"
                data-toggle="tab"
                to="#breakfast"
                role="tab"
                aria-controls="breakfast"
                aria-selected="true"
              >
                Dessert
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                className={`nav-link ${
                  selectewinelist === "winelist" ? "active" : ""
                }`}
                onClick={() => handleTabClick("winelist")}
                id="winelist-tab"
                data-toggle="tab"
                to="#winelist"
                role="tab"
                aria-controls="winelist"
                aria-selected="true"
              >
                Winelist
              </Link>
            </li> */}
          </ul>
          <div
            className="tab-content"
            id="myTabContent"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            {selectedTab === "breakfast" && (
              <div
                className="tab-pane fade show active"
                id="lunch"
                role="tabpanel"
                aria-labelledby="lunch-tab"
              >
                <DynamicMenu menuItems={breakfast} />
                {/* <div className="pdf_btn text-center mt-5">
                  <Link to="#" className="btn btn_primary">
                    download menu pdf
                  </Link>
                </div> */}
              </div>
            )}

            {selectedTab === "lunch" && (
              <div
                className="tab-pane fade show active"
                id="profile"
                role="tabpanel"
                aria-labelledby="dinner-tab"
              >
                <DynamicMenu menuItems={lunch} />
                {/* <div className="pdf_btn text-center mt-5">
                  <Link to="#" className="btn btn_primary">
                    download menu pdf
                  </Link>
                </div> */}
              </div>
            )}

            {selectedTab === "drinks" && (
              <div
                className="tab-pane fade show active"
                id="lunch"
                role="tabpanel"
                aria-labelledby="lunch-tab"
              >
                <DynamicMenu menuItems={drinks} />
                {/* <div className="pdf_btn text-center mt-5">
                  <Link to="#" className="btn btn_primary">
                    download menu pdf
                  </Link>
                </div> */}
              </div>
            )}
            {selectedTab === "dessert" && (
              <div
                className="tab-pane fade show active"
                id="lunch"
                role="tabpanel"
                aria-labelledby="lunch-tab"
              >
                <DynamicMenu menuItems={desserts} />
                {/* <div className="pdf_btn text-center mt-5">
                  <Link to="#" className="btn btn_primary">
                    download menu pdf
                  </Link>
                </div> */}
              </div>
            )}

            {/* {selectedTab === "breakfast" && (
              <div
                className="tab-pane fade show active"
                id="breakfast"
                role="tabpanel"
                aria-labelledby="breakfast-tab"
              >
                <div className="menulist_wraper">
                  <div className="menulist left_side">
                    <div className="menulist_box">
                      <h3 className="menusub_text">Starter</h3>
                      <ul>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Poached fried Beef Eggs </h4>
                              <span className="new">NEW</span>
                            </div>
                            <div className="doted" />
                            <span className="price">$16.00</span>
                          </div>
                          <div className="description">
                            <p>
                              {" "}
                              Tomatoes with Beef Eggs poached fried chorizo,
                              roasted roma.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Pear Tonic Prickly</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$25.00</span>
                          </div>
                          <div className="description">
                            <p>
                              salt, and pepper in a small bowl Siced beef in a
                              large bowl. Whisk together lemon juice, Soy sauce,
                              sugar,.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Crispy Skin Chicken</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$22.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Galjoen fish Australian lungfish sea, Shad angler
                              arapaima Medium beef steak grill pan
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="menulist_box">
                      <h3 className="menusub_text">wine list</h3>
                      <ul>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Kagoshima Wagyu</h4>
                              <span className="new">NEW</span>
                            </div>
                            <div className="doted" />
                            <span className="price">$16.00</span>
                          </div>
                          <div className="description">
                            <p>
                              {" "}
                              Beef, Eggs, poached, fried, with bacon, chorizo,
                              roasted roma tomatoes.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Lobster Tortellini</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$25.00</span>
                          </div>
                          <div className="description">
                            <p>
                              {" "}
                              Siced beef in a large bowl. Whisk together lemon
                              juice, soy sauce, sugar, salt, and pepper in a
                              small bowl.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Tokusen Wagyu</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$22.00</span>
                          </div>
                          <div className="description">
                            <p>
                              {" "}
                              Galjoen fish Australian lungfish sea, Shad angler
                              arapaima{" "}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="menulist right_side">
                    <div className="menulist_box">
                      <h3 className="menusub_text">Main Dishes</h3>
                      <ul>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Medium beef steak grill pan Grill pan </h4>
                              <span className="new">NEW</span>
                            </div>
                            <div className="doted" />
                            <span className="price">$16.00</span>
                          </div>
                          <div className="description">
                            <p>
                              {" "}
                              together lemon juice, soy sauce, sugar, together
                              lemon juice, soy sauce, sugar,{" "}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4> steak rawMeat marbled </h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$25.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Together lemon juice, soy sauce, sugar, salt, and
                              pepperin a small bowl. pour over beef and toss to
                              coat Siced beef in a large bowl. Whisk together
                              lemon juice.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Medium beef steak grill pan</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$22.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Galjoen fish Australian lungfish sea, Shad angler
                              arapaima
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Beef steak grill pan</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$18.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Beef, Eggs, poached, fried, with bacon, chorizo,
                              roasted roma tomatoes, mushrooms &amp; spinach.
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="menulist_box">
                      <h3 className="menusub_text">Dessert</h3>
                      <ul>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Kagoshima Wagyu</h4>
                              <span className="new">NEW</span>
                            </div>
                            <div className="doted" />
                            <span className="price">$16.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Beef, Eggs, poached, fried, with bacon, chorizo,
                              roasted roma tomatoes.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Banana Nut Pancakes</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$25.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Siced beef in a large bowl. Whisk together lemon
                              juice, soy sauce, sugar, salt, and pepper in a
                              small bowl.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Tokusen Wagyu</h4>
                              <span className="new">NEW</span>
                            </div>
                            <div className="doted" />
                            <span className="price">$22.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Galjoen fish Australian lungfish sea, Shad angler
                              arapaima
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="pdf_btn text-center mt-5">
                  <Link to="#" className="btn btn_primary">
                    download menu pdf
                  </Link>
                </div>
              </div>
            )} */}
            {/* {selectedTab === "winelist" && (
              <div
                className="tab-pane fade show active"
                id="winelist"
                role="tabpanel"
                aria-labelledby="winelist-tab"
              >
                <div className="menulist_wraper">
                  <div className="menulist left_side">
                    <div className="menulist_box">
                      <h3 className="menusub_text">Starter</h3>
                      <ul>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Beef steak grill pan</h4>
                              <span className="new">NEW</span>
                            </div>
                            <div className="doted" />
                            <span className="price">$16.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Beef, Eggs, poached, fried, with bacon, chorizo,
                              roasted roma tomatoes.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>raw marbled meat steak</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$25.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Siced beef in a large bowl. Whisk together lemon
                              juice, soy sauce, sugar, salt, and pepper in a
                              small bowl.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Medium beef steak grill pan</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$22.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Galjoen fish Australian lungfish sea, Shad angler
                              arapaima
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="menulist_box">
                      <h3 className="menusub_text">wine list</h3>
                      <ul>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Kagoshima Wagyu</h4>
                              <span className="new">NEW</span>
                            </div>
                            <div className="doted" />
                            <span className="price">$16.00</span>
                          </div>
                          <div className="description">
                            <p>
                              {" "}
                              Beef, Eggs, poached, fried, with bacon, chorizo,
                              roasted roma tomatoes.{" "}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Lobster Tortellini</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$25.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Siced beef in a large bowl. Whisk together lemon
                              juice, soy sauce, sugar, salt, and pepper in a
                              small bowl.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Tokusen Wagyu</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$22.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Galjoen fish Australian lungfish sea, Shad angler
                              arapaima
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="menulist right_side">
                    <div className="menulist_box">
                      <h3 className="menusub_text">Main Dishes</h3>
                      <ul>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Beef steak grill pan</h4>
                              <span className="new">NEW</span>
                            </div>
                            <div className="doted" />
                            <span className="price">$16.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Beef, Eggs, poached, fried, with bacon, chorizo,
                              roasted roma tomatoes.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>raw marbled meat steak</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$25.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Siced beef in a large bowl. Whisk together lemon
                              juice, soy sauce, sugar, salt, and pepper together
                              lemon juice, soy sauce, sugar, salt, and pepperin
                              a small bowl. pour over beef and toss to coat
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Medium beef steak grill pan</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$22.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Galjoen fish Australian lungfish sea, Shad angler
                              arapaima
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Beef steak grill pan</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$18.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Beef, Eggs, poached, fried, with bacon, chorizo,
                              roasted roma tomatoes, mushrooms &amp; spinach.
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="menulist_box">
                      <h3 className="menusub_text">Dessert</h3>
                      <ul>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Kagoshima Wagyu</h4>
                              <span className="new">NEW</span>
                            </div>
                            <div className="doted" />
                            <span className="price">$16.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Beef, Eggs, poached, fried, with bacon, chorizo,
                              roasted roma tomatoes.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Banana Nut Pancakes</h4>
                            </div>
                            <div className="doted" />
                            <span className="price">$25.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Siced beef in a large bowl. Whisk together lemon
                              juice, soy sauce, sugar, salt, and pepper in a
                              small bowl.
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="mainCource">
                            <div className="dishName">
                              <h4>Tokusen Wagyu</h4>
                              <span className="new">NEW</span>
                            </div>
                            <div className="doted" />
                            <span className="price">$22.00</span>
                          </div>
                          <div className="description">
                            <p>
                              Galjoen fish Australian lungfish sea, Shad angler
                              arapaima
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="pdf_btn text-center mt-5">
                  <Link to="#" className="btn btn_primary">
                    download menu pdf
                  </Link>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </section>
    </>
  );
}

export default OfferMenu2;
