import React, { useEffect, useRef } from "react";
import Header from "../Header/Main";
import Footer from "../Footer/Main";
import Gallery2 from "../MenMenuList/Gallery2";
import OfferMenu2 from "../MenMenuList/OfferMenu2";
import OurLocations from "../MenHomeTwo/OurLocations";

import Aos from "aos";

function Main() {
  const myRef = useRef(null);

  // when this page is landed on, i need to scroll to OurLocations
  useEffect(() => {
    // myRef.current.scrollIntoView();
    // scroll into view smooth
    myRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  return (
    <div className="page_wrapper">
      <Header />
      <Gallery2 />
      {/* <OfferMenu2/> */}
      <OurLocations passedRef={myRef} isHome={false} />
      <Footer />
    </div>
  );
}

export default Main;
