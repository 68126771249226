import React from "react";
import Slider from "react-slick";

const Menuflow = {
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 6.47, // Reduced for mobile view
        autoplaySpeed: 3000,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 10,
        autoplaySpeed: 2000, // Slightly faster speed for tablet view
      },
    },
    {
      breakpoint: 1024, // Add a breakpoint for desktop view
      settings: {
        slidesToShow: 10,
        autoplaySpeed: 1000, // Original speed for desktop view
      },
    },
  ],
};

function Menulist() {
  return (
    <>
      <div className="menu_list_section">
        <Slider
          className="Slick-Menu"
          id="menu_list_flow"
          {...Menuflow}
          infinite={true}
          arrows={false}
          slidesToShow={10}
          autoplay={true}
          autoplaySpeed={1000}
        >
          <div className="item" id="scroll_item">
            <div className="text_block">
              <span>POLLO ASADO </span>
              <span className="mark_star">*</span>
            </div>
          </div>
          <div className="item">
            <div className="text_block">
              <span> CAMARONES </span>
              <span className="mark_star">*</span>
            </div>
          </div>
          <div className="item">
            <div className="text_block">
              <span> CHIMICHANGAS </span>
              <span className="mark_star">*</span>
            </div>
          </div>
          <div className="item">
            <div className="text_block">
              <span> Tacos </span>
              <span className="mark_star">*</span>
            </div>
          </div>
          <div className="item">
            <div className="text_block">
              <span>Chilaquiles </span>
              <span className="mark_star">*</span>
            </div>
          </div>
          <div className="item">
            <div className="text_block">
              <span> Carnitas </span>
              <span className="mark_star">*</span>
            </div>
          </div>
          <div className="item">
            <div className="text_block">
              <span>Enchiladas </span>
              <span className="mark_star">*</span>
            </div>
          </div>
          <div className="item">
            <div className="text_block">
              <span> Quesadillas </span>
              <span className="mark_star">*</span>
            </div>
          </div>
          <div className="item">
            <div className="text_block">
              <span> Tortas </span>
              <span className="mark_star">*</span>
            </div>
          </div>
          <div className="item">
            <div className="text_block">
              <span>Mariscos </span>
              <span className="mark_star">*</span>
            </div>
          </div>
          <div className="item">
            <div className="text_block">
              <span> Fajitas </span>
              <span className="mark_star">*</span>
            </div>
          </div>
          <div className="item">
            <div className="text_block">
              <span> Tamales </span>
              <span className="mark_star">*</span>
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
}

export default Menulist;
