import React from "react";
import { Link } from "react-router-dom";
import chefimg from "../../assets/images/banner_right_shap.png";
import chefimg01 from "../../assets/images/about/holder.jpg";
import Img01 from "../../assets/images/chef_cap.png";
import Img02 from "../../assets/images/chef_text.png";

function Chef() {
  return (
    <>
      <section className="chef_section row_am bg_white_textcher">
        <div className="side_shape position-absolute">
          <img src={chefimg} alt="img" />
        </div>
        <div className="container">
          <div className="row my-2 my-sm-4">
            <div
              className="col-md-6"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="chef_img">
                <img
                  className="radius_img img-fluid"
                  src={chefimg01}
                  alt="img"
                />
              </div>
              <h3 className="chef_name">
                <span>The Heart of La Hacienda</span> Our Kitchen{" "}
              </h3>
            </div>
            <div className="col-md-6">
              <div
                className="abt_chef"
                data-aos="fade-up"
                data-aos-duration={1500}
              >
                <div className="banner_badge">
                  <img className="img-fluid" src={Img01} alt="img" />
                  <div className="round_anim">
                    {/* <img src={Img02} alt="img" /> */}
                  </div>
                </div>
                <div className="section_title">
                  <span className="sub_text">Our Culinary Team</span>
                  <h2 className="mb-3">
                    Passionate Experts Crafting <br /> Authentic Mexican Dishes
                  </h2>
                  <p>
                    At La Hacienda Mexican Restaurant, we take pride in our dedicated team of
                    culinary experts who bring the essence of Mexico to your
                    plate. Each member of our kitchen staff contributes their
                    unique touch to our dishes, ensuring a delightful and
                    authentic dining experience. From traditional recipes to
                    innovative flavors, our team works tirelessly to present the
                    best of Mexican cuisine.
                  </p>
                </div>
                {/* <Link to="/ourchef" className="btn btn_text">
                  meet our team <i className="icofont-long-arrow-right" />
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Chef;
