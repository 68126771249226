import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import DishImg1 from "../../assets/images/home/fork_steak_side.png";
import DishImg2 from "../../assets/images/home/steak_side.png";

const Popular = {
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};

function PopularDish() {
  return (
    <>
      <section className="row_inner_am popular_dish_section">
        <div className="side_shape">
          <img className="right_side" src={DishImg2} alt="img" />
          <img className="left_side" src={DishImg1} alt="img" />
        </div>
        <div className="container">
          <div
            className="section_title text-center"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <h1 style={{ marginBottom: 0 }}>La Hacienda</h1>
            <span className="sub_text">Mexican Restaurant</span>
            {/* <span className="sub_text">Welcome to La Hacienda</span>
            <h1 style={{ marginBottom: 0 }}>Mexican Restaurant</h1> */}
            <p>We Only Serve Fresh Food. Food you will always remember.</p>
          </div>
          <div
            id="popular_dish"
            className="dishList_slider"
            data-aos="fade-in"
            data-aos-duration={1500}
          >
            <Slider
              className="Slider-dishes"
              {...Popular}
              slidesToShow={4}
              arrows={false}
              margin={15}
              autoplay={true}
              autoplaySpeed={2000}
            >
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src="../../assets/images/food/food_img-1.jpeg" alt="img" />
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src="assets/images/food/food_img-2.jpeg" alt="img" />
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src="assets/images/food/food_img-3.jpeg" alt="img" />
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src="assets/images/food/food_img-4.jpeg" alt="img" />
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src="assets/images/food/food_img-5.jpeg" alt="img" />
                  </div>

                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src="assets/images/food/food_img-6.jpeg" alt="img" />
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src="assets/images/food/food_img-7.jpeg" alt="img" />
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="dish_card">
                  <div className="img">
                    <img src="assets/images/food/food_img-9.jpeg" alt="img" />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <div className="text-center mb-md-4">
            <Link to="/menu" className="btn btn_primary">
              view our full menu
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default PopularDish;
