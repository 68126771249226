import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Test03 from "../../assets/images/review/review1.jpg";
import Test02 from "../../assets/images/review/review2.jpg";
import Test01 from "../../assets/images/review/review3.jpg";

const Testslider = {
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};

function Testimonial() {
  return (
    <>
      <section className="row_inner_am testimonial_section bg_white_textcher">
        <div className="container-fluid">
          <div
            className="section_title text-center"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <span className="sub_text">Tasty words</span>
            <h2>customer reviews</h2>
          </div>
          <div
            className="testimonial_wraper"
            data-aos="fade-in"
            data-aos-duration={1500}
          >
            <Slider
              className=""
              id="testimonial_slider"
              {...Testslider}
              arrows={false}
              autoplay={true}
              autoplaySpeed={2000}
            >
              <div className="item">
                <div className="testimonial_inner">
                  <div className="img">
                    <img src={Test01} alt="img" />
                  </div>
                  <div className="text">
                    <span className="quote">“</span>
                    <div className="star">
                      <span>
                        <i className="icofont-star" />
                      </span>
                      <span>
                        <i className="icofont-star" />
                      </span>
                      <span>
                        <i className="icofont-star" />
                      </span>
                      <span>
                        <i className="icofont-star" />
                      </span>
                      <span>
                        <i className="icofont-star" />
                      </span>
                    </div>
                    <h2>
                      Food and service was amazing. Can't wait to go back! Also,
                      had to share a pic of the delicious fried ice cream
                    </h2>
                    <h3>
                      Carrisa Garcia,{" "}
                      <span className="designation">via Google Reviews</span>
                    </h3>
                    <span className="quote text-right d-block">”</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonial_inner">
                  <div className="img">
                    <img src={Test02} alt="img" />
                  </div>
                  <div className="text">
                    <span className="quote">“</span>
                    <div className="star">
                      <span>
                        <i className="icofont-star" />
                      </span>
                      <span>
                        <i className="icofont-star" />
                      </span>
                      <span>
                        <i className="icofont-star" />
                      </span>
                      <span>
                        <i className="icofont-star" />
                      </span>
                      <span>
                        <i className="icofont-star" />
                      </span>
                    </div>
                    <h2>
                      Group of 11 ... I had the shrimp fajitas Excellent and
                      fresh. Loved the fajitas and hot steamy plate. Was busy
                      but everyone was taken care of
                    </h2>
                    <h3>
                      Tony Z,{" "}
                      <span className="designation">via Google Reviews</span>
                    </h3>
                    <span className="quote text-right d-block">”</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimonial_inner">
                  <div className="img">
                    <img src={Test03} alt="img" />
                  </div>
                  <div className="text">
                    <span className="quote">“</span>
                    <div className="star">
                      <span>
                        <i className="icofont-star" />
                      </span>
                      <span>
                        <i className="icofont-star" />
                      </span>
                      <span>
                        <i className="icofont-star" />
                      </span>
                      <span>
                        <i className="icofont-star" />
                      </span>
                      <span>
                        <i className="icofont-star" />
                      </span>
                    </div>
                    <h2>
                      This is by far one of my favorite places to eat. I love
                      how friendly and quick the staff are. They always make
                      sure you have a great time. In a world of birria be a
                      carnita
                    </h2>
                    <h3>
                      Travis B,{" "}
                      <span className="designation">via Google Reviews</span>
                    </h3>
                    <span className="quote text-right d-block">”</span>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          {/* <div
            className="text-center"
            data-aos="fade-in"
            data-aos-duration={1500}
          >
            <Link to="/review" className="btn btn_primary">
              more testimonials
            </Link>
          </div> */}
        </div>
      </section>
    </>
  );
}

export default Testimonial;
