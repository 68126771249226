import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo/stacked.png";
import placeholder from "../../assets/images/logo/250.png";
import MenuImg from "../../assets/images/logo/stacked.png";
import house from '../../assets/images/logo/house_logo.png'
import house2 from '../../assets/images/logo/house.png';
import Notification from "../Notification/Notification";

function Main() {
  const [active, setActive] = useState();
  const [Nav, setNav] = useState(false);
  const [Home, setHome] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleMenuItemClick = () => {
    console.log("opening page");
    // closeMenu();
    // setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      {active && (
        <div
          className="menu-backdrop"
          style={{ opacity: "1", visibility: "visible" }}
        >
        </div>
      )}

      <header className={`fixed ${menuOpen ? "menu-open" : ""}`}>
        {/* <Notification text="Important Notice: Our Salt Lake location located at 1248 S Redwood Rd, will be closed from Tuesday July 2nd thru Monday July 8th. Sorry for the inconvenience.  " bg="red" closable={false} /> */}
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg">
            <Link style={{ padding: 10 }} className="navbar-brand" to="/">
              <img src={placeholder} alt="image" />
              <img src={house2} alt="" />
            </Link>
            <button
              className={`navbar-toggler ${menuOpen ? "open" : ""}`}
              type="button"
              onClick={toggleMenu}
            >
              <span
                className="navbar-toggler-icon"
                onClick={() => setHome(Home === true ? false : true)}
              >
                <span className={`toggle-wrap ${menuOpen ? "active" : ""}`}>
                  <span className="toggle-bar"></span>
                </span>
              </span>
            </button>
            <div
              className={`collapse navbar-collapse ${menuOpen ? "show" : ""}`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/"
                    onClick={handleMenuItemClick}
                    style={{
                      // nowrap on text
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Home
                  </Link>
                  {/* <span className="drp_btn">
                    <i className="icofont-rounded-down" />
                  </span>
                  <div className={`sub_menu ${isDropdownOpen ? 'open' : ''}`}>
                    <ul style={{ display: "block" }}>
                      <li>
                        <Link to="/">Home Defoult</Link>
                      </li>
                      <li>
                        <Link to="/home2">Home Slider Hero</Link>
                      </li>
                      <li>
                        <Link to="/home3">Home Dish List</Link>
                      </li>
                    </ul>
                  </div> */}
                </li>
                <li className="nav-item ">
                  <Link
                    className="nav-link"
                    to="/menu"
                    onClick={handleMenuItemClick}
                    style={{
                      // nowrap on text
                      whiteSpace: 'nowrap',
                    }}
                  >
                    MENU
                  </Link>
                  {/* <span className="drp_btn">
                    <i className="icofont-rounded-down" />
                  </span>
                  <div className="sub_menu">
                    <ul>
                      <li>
                        <Link to="/menulist1">Menu List 1</Link>
                      </li>
                      <li>
                        <Link to="/menulist2">Menu List 2</Link>
                      </li>
                      <li>
                        <Link to="/menulist3">Menu List 3</Link>
                      </li>
                    </ul>
                  </div> */}
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/about"
                    onClick={handleMenuItemClick}
                    style={{
                      // nowrap on text
                      whiteSpace: 'nowrap',
                    }}
                  >
                    ABOUT US
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/locations"
                    onClick={handleMenuItemClick}
                    style={{
                      // nowrap on text
                      whiteSpace: 'nowrap',
                    }}
                  >
                    LOCATIONS
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/ourchef" onClick={handleMenuItemClick}>
                    OUR CHEFS
                  </Link>
                </li> */}
                {/* <li className="nav-item has_dropdown">
                  <Link className="nav-link" to="#" onClick={handleMenuItemClick}>
                    Pages
                  </Link>
                  <span className="drp_btn">
                    <i className="icofont-rounded-down" />
                  </span>
                  <div className="sub_menu">
                    <ul>
                      <li>
                        <Link to="/bloglist">Blog List </Link>
                      </li>
                      <li>
                        <Link to="/blogdetail">Blog Details</Link>
                      </li>
                      <li>
                        <Link to="/reservation1">reservation 1</Link>
                      </li>
                      <li>
                        <Link to="/reservation2">reservation 2</Link>
                      </li>
                      <li>
                        <Link to="/review">reviews</Link>
                      </li>
                      <li>
                        <Link to="/gallery">gallery</Link>
                      </li>
                    </ul>
                  </div>
                </li> */}
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/contact"
                    onClick={handleMenuItemClick}
                    style={{
                      // nowrap on text
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Contact
                  </Link>
                </li>
                <li className="nav-item contact_number">
                  <Link className="nav-link" >
                    <span>come visit us or order online :</span>
                  </Link>
                </li>
                <li className="d-lg-none">
                  <Link
                    to="/locations"
                    className="btn btn_primary"
                    onClick={handleMenuItemClick}
                    style={{
                      // nowrap on text
                      whiteSpace: 'nowrap',
                    }}
                  >
                    ORDER NOW
                  </Link>
                </li>
              </ul>
            </div>

            <div className="action_bar">
              <Link
                to="/locations"
                className="btn btn_primary"
                onClick={handleMenuItemClick}
                style={{
                  // nowrap on text
                  whiteSpace: 'nowrap',
                }}
              >
                ORDER NOW
              </Link>
              <div className="bar" onClick={() => setNav(true)}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </nav>
        </div>
      </header>

      {Nav && (
        <div className={`right_sidemenu ${Nav && "active"}`}>
          <span className="close_rightmenu" onClick={() => setNav(false)}>
            <i className="icofont-close-circled" />
          </span>
          <div className="menu_inner_content">
            <Link to="#" className="side_logo">
              <img src={logo} alt="image" />
            </Link>
            <ul className="contact_listing">
              <li>
                <p className="sub_title">Call us</p>
                <div className="cnt_block">
                  <Link style={{ whiteSpace: 'nowrap' }} to="tel:+18019730738">SALT LAKE CITY - (801) 973-0738</Link>
                  <span>
                    <i className="icofont-ui-call" />
                  </span>
                </div>
                <div className="cnt_block">
                  <Link to="tel:+18015712737">DRAPER - (801) 571-2737 </Link>
                  <span>
                    <i className="icofont-ui-call" />
                  </span>
                </div>
                <div className="cnt_block">
                  <Link to="tel:+18015597374" style={{ whiteSpace: 'nowrap' }}>SOUTH JORDAN - (801) 559-7374</Link>
                  <span>
                    <i className="icofont-ui-call" />
                  </span>
                </div>
                <div className="cnt_block">
                  <Link to="tel:++18019530667" style={{ whiteSpace: 'nowrap' }}>HACIENDA GRILL - (801) 953-0667</Link>
                  <span>
                    <i className="icofont-ui-call" />
                  </span>
                </div>
              </li>
              <li>
                <p className="sub_title">Email us</p>
                <div className="cnt_block">
                  <Link to="/contact">
                    la_hacienda@comcast.net
                  </Link>
                  <span>
                    <i className="icofont-envelope-open" />
                  </span>
                </div>
              </li>
              <li>
                <p className="sub_title">Find us</p>
                <div className="cnt_block">
                  <a href="https://www.google.com/maps/place/La+Hacienda+Salt+Lake/@40.7423285,-111.9424174,17z/data=!3m1!4b1!4m6!3m5!1s0x8752f4bcde605a91:0x7df64193be3901f6!8m2!3d40.7423285!4d-111.9398425!16s%2Fg%2F1wh4gb9k?entry=ttu" target="_blank">La Hacienda, Salt Lake City UT 84104</a>
                  <span>
                    <i className="icofont-location-pin" />
                  </span>
                </div>
                <div className="cnt_block">
                  <a href="https://www.google.com/maps/place/La+Hacienda+Draper/@40.5252998,-111.8916414,17z/data=!3m1!4b1!4m6!3m5!1s0x87528716d0a0bc39:0x1bfbd46641479e5e!8m2!3d40.5252998!4d-111.8890665!16s%2Fg%2F1tk8p1vy?entry=ttu" target="_blank">La Hacienda, Draper UT 84020</a>
                  <span>
                    <i className="icofont-location-pin" />
                  </span>
                </div>
                <div className="cnt_block">
                  <a href="https://www.google.com/maps/place/La+Hacienda+South+Jordan/@40.5384869,-111.9795646,17z/data=!3m1!4b1!4m6!3m5!1s0x87528566cdda3cb7:0xe763d19b2cdeaa19!8m2!3d40.5384869!4d-111.9769897!16s%2Fg%2F11k2jr2rdz?entry=ttu" target="_blank">La Hacienda, South Jordan UT 84095</a>
                  <span>
                    <i className="icofont-location-pin" />
                  </span>
                </div>
                <div className="cnt_block">
                  <a href="https://www.google.com/maps/place/Hacienda+Mexican+Grill/@40.7863068,-111.9433721,17z/data=!3m1!4b1!4m6!3m5!1s0x8752f40dfb95461b:0xef3e4f5a637d8de!8m2!3d40.7863028!4d-111.9407972!16s%2Fg%2F11c0rh247k?entry=ttu" target="_blank">Hacienda Mexican Grill, Salt Lake City - SISTER COMPANY</a>
                  <span>
                    <i className="icofont-location-pin" />
                  </span>
                </div>
              </li>
            </ul>
            <Link to="/locations" className="btn btn_primary">
              ORDER NOW
            </Link>
            {/* <figure className="tab_img">
              <img src={MenuImg} alt="img" />
            </figure> */}
          </div>
        </div>
      )}
    </>
  );
}

export default Main;
