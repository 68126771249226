import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Icon01 from "../../assets/images/title_icon.png";
import Icon02 from "../../assets/images/who_icon.png";
import Icon03 from "../../assets/images/who_icon2.png";
import gallery1 from "../../assets/images/scroll/laHacienda1.jpeg";
import gallery2 from "../../assets/images/locations/slc-lahacienda.jpg";
import gallery3 from "../../assets/images/scroll/lahacienda3.jpeg";
import gallery4 from "../../assets/images/scroll/lahacienda4.jpeg";
import gallery5 from "../../assets/images/scroll/lahacienda5.jpeg";
import gallery6 from "../../assets/images/scroll/lahacienda6.jpeg";
import gallery7 from "../../assets/images/locations/draper-lahacienda.jpg";
import gallery8 from "../../assets/images/scroll/lahacienda2.jpeg";

const Weslider = {
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 10,
        // autoplaySpeed: 10000,
        slidesToScroll: 1,
        // Reduced for mobile view
        // autoplaySpeed: 3000,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 10,
      },
    },
  ],
};

function WhoWe() {
  return (
    <>
      <div className="row_inner_am who_we_section who_version_two light_texchur">
        <div className="container">
          <div
            className="section_title text-center"
            data-aos="fade-up"
            data-aos-duration={1500}
          >
            <div className="icon">
              <img src={Icon01} alt="img" />
            </div>
            <span className="sub_text">Who we are</span>
            <h2>
              Discover the Vibrant Heart of Mexican Cuisine <br />
              <span className="heading_img">
                {/* <img src={Icon02} alt="img" /> */}
              </span>
              Experience the Fusion of Tradition & Flavors, <br />
              Authentic Mexican Dishes & Welcoming Ambiance, <br />
              Each Bite a Journey to Remember.{" "}
              <span className="heading_img">

              </span>{" "}
              Join us <br /> for an unforgettable culinary adventure.
            </h2>
          </div>
        </div>
        <div className="gallery_slider">
          <Slider
            className="Slider-Who"
            id="gallery_flow_right"
            data-aos="fade-in"
            data-aos-duration={1500}
            {...Weslider}
            slidesToShow={11.5}
            arrows={false}
            autoplay={true}
            autoplaySpeed={1000}
          >
            <div className="item">
              <div className="img">
                <img src={gallery1} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery2} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery3} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery4} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery1} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery2} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery3} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery4} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery1} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery2} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery3} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery4} alt="img" />
              </div>
            </div>
          </Slider>
          <Slider
            className="Slider-Who"
            id="gallery_flow_left"
            data-aos="fade-in"
            data-aos-duration={1500}
            {...Weslider}
            rtl={true}
            slidesToShow={11.5}
            arrows={false}
            autoplay={true}
            autoplaySpeed={1000}
          >
            <div className="item">
              <div className="img">
                <img src={gallery5} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery6} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery7} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery8} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery5} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery6} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery7} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery8} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery5} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery6} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery7} alt="img" />
              </div>
            </div>
            <div className="item">
              <div className="img">
                <img src={gallery8} alt="img" />
              </div>
            </div>
          </Slider>
        </div>
        <div
          className="text-center"
          data-aos="fade-in"
          data-aos-duration={1500}
        >
          <Link to="/About" className="btn btn_primary">
            read our story
          </Link>
        </div>
      </div>
    </>
  );
}

export default WhoWe;
