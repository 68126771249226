import { Link } from "react-router-dom";
import React, { useState } from "react";
import Slider from "react-slick";
import BG from "../../assets/images/video_poster.png";
import icon01 from "../../assets/images/title_icon.png";
import menuimg from "../../assets/images/food/food_img-1.jpeg";
import menu01 from "../../assets/images/food/food_img-2.jpeg";
import menu02 from "../../assets/images/food/drinks_img.jpeg";
import menu03 from "../../assets/images/flan_dessert.jpg";

import videoimg from "../../assets/images/video_text.png";
import playimg from "../../assets/images/play.png";

const DeliciousMenu = {
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 4,
      },
    },

  ],
};

function Deliciousmenu() {
  const [ytshow, setytShow] = useState(false);

  return (
    <>
      <section className="row_am delicous_menu">
        <div className="container">
          <div
            className="section_title text-center"
            data-aos="fade-up"
            data-aos-duration={8000}
          >
            <span className="icon">
              <img src={icon01} alt="img" />
            </span>
            <span className="sub_text">Flavorful Fiesta</span>
            <h2>
              Experience the heart of Mexico in every dish. <br />
              Savor the Richness of Authentic Flavors & <br />A Welcoming
              Ambiance with Our Signature Dishes.
            </h2>
          </div>
          <div className="menu_listing row_am">
            <div
              className="menu_listing_block"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="diplay_state">
                <div className="menu_name">
                  <h3>Lunch</h3>
                </div>
                <div className="list_running">
                  <Slider
                    className=" menuTextSlider"
                    {...DeliciousMenu}
                    slidesToShow={4}
                    autoplay={true}
                    autoplaySpeed={1000}
                    arrows={false}
                  >
                    <div className="item">
                      <div className="wrp">
                        <span>Botanas</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Combinations</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Burritos</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Tacos</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Enchiladas</span>
                      </div>
                    </div>
                  </Slider>
                </div>
                <span className="direction_pointer">
                  <i className="icofont-long-arrow-right" />
                </span>
              </div>
              <div className="hover_state">
                <div className="menu_img">
                  <img src={menu01} alt="img" />
                </div>
                <div className="menu_name">
                  <h3>
                    <Link to="/menu">Lunch</Link>
                  </h3>
                </div>
                <div className="menuMobileLIst">
                  <ul>
                    <li>
                      <span>Botanas</span>
                    </li>
                    <li>
                      <span>Combinations</span>
                    </li>
                    <li>
                      <span>Burritos</span>
                    </li>
                    <li>
                      <span>Tacos</span>
                    </li>
                    <li>
                      <span>Enchiladas</span>
                    </li>
                  </ul>
                </div>
                <Link to="/menu" className="btn btn_primary">
                  View Our Menu <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
            <div
              className="menu_listing_block"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="diplay_state">
                <div className="menu_name">
                  <h3>DINNER</h3>
                </div>
                <div className="list_running">
                  <Slider
                    className="menuTextSlider"
                    slidesToShow={4}
                    autoplay={true}
                    autoplaySpeed={1000}
                    arrows={false}
                  >
                    <div className="item">
                      <div className="wrp">
                        <span>Tamales</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Enchiladas</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Burritos</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Quesadillas</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Meat-plates</span>
                      </div>
                    </div>
                  </Slider>
                </div>
                <span className="direction_pointer">
                  <i className="icofont-long-arrow-right" />
                </span>
              </div>
              <div className="hover_state">
                <div className="menu_img">
                  <img src={menuimg} alt="img" />
                </div>
                <div className="menu_name">
                  <h3>
                    <Link to="/menu">Dinner</Link>
                  </h3>
                </div>
                <div className="menuMobileLIst">
                  <ul>
                    <li>
                      <span>Meat-plates</span>
                    </li>
                    <li>
                      <span>Quesadillas</span>
                    </li>
                    <li>
                      <span>Enchiladas</span>
                    </li>
                    <li>
                      <span>Tamales</span>
                    </li>
                    <li>
                      <span>Burritos</span>
                    </li>
                  </ul>
                </div>
                <Link to="/menu" className="btn btn_primary">
                  View Our Menu <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
            <div
              className="menu_listing_block"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="diplay_state">
                <div className="menu_name">
                  <h3>DRINKS</h3>
                </div>
                <div className="list_running">
                  <Slider
                    className="menuTextSlider"
                    slidesToShow={4}
                    autoplay={true}
                    autoplaySpeed={1000}
                    arrows={false}
                  >
                    <div className="item">
                      <div className="wrp">
                        <span>Margaritas</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Beer</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Daiquiris</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Sodas</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Juices</span>
                      </div>
                    </div>
                  </Slider>
                </div>
                <span className="direction_pointer">
                  <i className="icofont-long-arrow-right" />
                </span>
              </div>
              <div className="hover_state">
                <div className="menu_img">
                  <img src={menu02} alt="img" />
                </div>
                <div className="menu_name">
                  <h3>
                    <Link to="/menu">DRINKS</Link>
                  </h3>
                </div>
                <div className="menuMobileLIst">
                  <ul>
                    <li>
                      <span>Sodas</span>
                    </li>
                    <li>
                      <span>Juices</span>
                    </li>
                    <li>
                      <span>beer</span>
                    </li>
                    <li>
                      <span>Daiquiris</span>
                    </li>
                    <li>
                      <span>Margaritas</span>
                    </li>
                  </ul>
                </div>
                <Link to="/menu" className="btn btn_primary">
                  View Our Menu <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
            <div
              className="menu_listing_block"
              data-aos="fade-up"
              data-aos-duration={1500}
            >
              <div className="diplay_state">
                <div className="menu_name">
                  <h3>DESSERT</h3>
                </div>
                <div className="list_running">
                  <Slider
                    className="menuTextSlider"
                    slidesToShow={4}
                    autoplay={true}
                    autoplaySpeed={500}
                    arrows={false}
                  >
                    <div className="item">
                      <div className="wrp">
                        <span>Flan</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Fried ice cream</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Sopapillas</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Fried ice cream</span>
                      </div>
                    </div>
                    <div className="item">
                      <div className="wrp">
                        <span>Flan</span>
                      </div>
                    </div>
                  </Slider>
                </div>
                <span className="direction_pointer">
                  <i className="icofont-long-arrow-right" />
                </span>
              </div>
              <div className="hover_state">
                <div className="menu_img">
                  <img src={menu03} alt="img" />
                </div>
                <div className="menu_name">
                  <h3>
                    <Link to="/menu">DESSERT</Link>
                  </h3>
                </div>
                <div className="menuMobileLIst">
                  <ul>
                    <li>
                      <span>Flan</span>
                    </li>
                    <li>
                      <span>Sopapillas</span>
                    </li>
                    <li>
                      <span>Fried ice cream</span>
                    </li>
                    <li>
                      <span>Flan</span>
                    </li>
                    <li>
                      <span>Sopapillas</span>
                    </li>
                  </ul>
                </div>
                <Link to="/menu" className="btn btn_primary">
                  View Our Menu <i className="icofont-long-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* TODO: BRING BACK VIDEO IF GABE WANTS */}
        {/* <div className="video_wraper">
          <div className="action_box" style={{ backgroundImage: `url(${BG})` }}>
            <div
              className="play_vtn d-flex justify-content-center align-items-center"
              onClick={() => setytShow(true)}
            >
              <div className="round_anim">
                <img src={videoimg} alt="img" />
              </div>
              <div className="action_btn">
                <Link
                  to="#"
                  className="popup-youtube play-button"
                  data-url="https://www.youtube.com/embed/NC9KlaxtfLg?autoplay=1&mute=1"
                  data-toggle="modal"
                  data-target="#myModal"
                  title="XJj2PbenIsU"
                >
                  <img src={playimg} alt="img" />
                </Link>
              </div>
            </div>
          </div>
        </div> */}
      </section>

      {ytshow && (
        <div
          className="modal fade youtube-video show"
          id="myModal"
          tabIndex={-1}
          role="dialog"
          style={{ paddingRight: 17, display: "block" }}
          aria-modal="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content" onClick={() => setytShow(false)}>
              <button
                id="close-video"
                type="button"
                className="button btn btn-default text-right"
                data-dismiss="modal"
                onClick={() => setytShow(false)}
              >
                <i className="icofont-close-line-circled" />
              </button>
              <div className="modal-body">
                <div id="video-container" className="video-container">
                  <iframe
                    id="youtubevideo"
                    width={640}
                    height={360}
                    allowFullScreen=""
                    src="https://www.youtube.com/embed/NC9KlaxtfLg?autoplay=1&mute=1"
                  />
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Deliciousmenu;
